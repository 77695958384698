import '../styles/globals.css';
import React, { useEffect, useRef } from 'react';
import MainLayout from '../layouts/MainLayout';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/Lib/graphql/apollo-client';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import { Meta } from '@/Components/meta/meta';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { StructuredData } from '@/Components/structured-data/structured-data';
import { ToastContainer } from 'react-toastify';
import { useSetupIntercom } from '@/Lib/hooks/useSetupIntercom';
import { useRudderstackPage } from '@/Lib/hooks/rudderstack/useRudderstackPage';
import { GlobalStateProvider } from '@/State/global/global.provider';
import queryString from 'query-string';
import { AppPropsWithLayout } from '@/Lib/types/app';
import { ABTestProvider } from '@/Lib/hooks/ab-test/ab-test.provider';
import { SharedStaticDataProvider } from '@/Lib/hooks/static-data/shared-static-data.provider';
import { SharedPageStaticData } from '@/Lib/types/page';
import { ScrollProvider } from '@/Lib/contexts/ScrollContext';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const hasParamsSaved = useRef(false);
  const params = useRouter().asPath.split(/\?/)[1];
  const query = queryString.parse(params);

  useSetupIntercom();
  useRudderstackPage();

  const Layout = Component.Layout || MainLayout;

  if (
    typeof window !== 'undefined' &&
    !hasParamsSaved.current &&
    Object.keys(query).length
  ) {
    hasParamsSaved.current = true;
  }

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      import('intersection-observer');
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <SharedStaticDataProvider<SharedPageStaticData>
        data={{
          navigationData: pageProps.navigationData,
          headerData: pageProps.headerData,
          offices: pageProps.offices,
        }}
      >
        <GlobalStateProvider>
          <ABTestProvider>
            <CookiesProvider>
              <Meta data={pageProps.meta} />
              <StructuredData />
              <ScrollProvider>
                <Layout pageProps={pageProps}>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={router.asPath.split('?')[0]}
                      timeout={300}
                      classNames="page-transition"
                    >
                      <>
                        <Component {...pageProps} />
                        <ToastContainer />
                      </>
                    </CSSTransition>
                  </SwitchTransition>
                </Layout>
              </ScrollProvider>
            </CookiesProvider>
          </ABTestProvider>
        </GlobalStateProvider>
      </SharedStaticDataProvider>
    </ApolloProvider>
  );
}
export default MyApp;
