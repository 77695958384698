// context/ScrollContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useRouter } from 'next/router';

interface ScrollContextProps {
  disableScroll: () => void;
  enableScroll: () => void;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [lockCount, setLockCount] = useState(0);
  const router = useRouter();

  // Log lockCount whenever it changes
  useEffect(() => {
    console.log('Current lockCount:', lockCount);
    if (lockCount > 0) {
      // Disable scroll
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      console.log(`Scroll disabled. Lock count: ${lockCount}`);
    } else {
      // Enable scroll
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
      console.log('Scroll enabled.');
    }
  }, [lockCount]);

  // Reset lockCount on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setLockCount(0); // Reset lock count on route change
      console.log('Route changed. Lock count reset to 0.');
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const disableScroll = () => {
    setLockCount((count) => count + 1);
    // Removed immediate log to prevent inconsistency
  };

  const enableScroll = () => {
    setLockCount((count) => Math.max(count - 1, 0));
    // Removed immediate log to prevent inconsistency
  };

  return (
    <ScrollContext.Provider value={{ disableScroll, enableScroll }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
